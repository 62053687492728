import React from 'react'

import TrianglesSVG from 'app/assets/icons/homePage/big-triangles.svg'
import Box from 'app/components/base/Box'

const VideosContainer: RFC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <Box
      sx={{
        position: 'relative',
        mt: 4.5,
        py: 8,
        backgroundColor: '#F3FCFB',
        overflow: 'hidden'
      }}
    >
      <Box
        sx={{
          position: 'absolute',
          top: '0px',
          right: '0px',
          display: { xs: 'none', md: 'block' }
        }}
      >
        <TrianglesSVG />
      </Box>
      {children}
    </Box>
  )
}

export default VideosContainer
